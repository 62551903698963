<template>
  <div class="px-5">
    <Form
      title="Crear partido"
      ref="gameForm"
      :loading.sync="loading"
      @save="save"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: false,
      form: {}
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    save() {
      let form = this.$refs.gameForm.form
      
      //form.hour = 03:00 PM
      let hour = form.hour.indexOf('PM') > 0
        ? (parseInt(form.hour.substring(0,2)) + 12 == 24 ? 12 : parseInt(form.hour.substring(0,2)) + 12)
        : form.hour.substring(0,2)
      
      let minute = form.hour.substring(3,5)

      this.loading = true
      this.$store
        .dispatch("global/create", {
          payload: {
            id_category: form.id_category.split("$$")[0],
            id_subcategory: form.id_category.split("$$")[1],
            id_tournament: form.id_tournament,
            id_home_team: form.id_home_team,
            id_visit_team: form.id_visit_team,
            date: new Date(form.date).toISOString().split("T")[0],
            hour: hour + ':' + minute,
            round: form.round,
            location: form.location
          },
          route: "/game/save",
          module: "game"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master", response.code, this.$snotify)
          })
          this.loading = false
          if ([200, 201].includes(response.code)) {
            this.$router.push("/partidos")
          }
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {}
}
</script>
